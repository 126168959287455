import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { useEffect } from 'react'

import { ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import koKR from 'antd/locale/ko_KR'
import dayjs from 'dayjs'
import { SSRConfig, appWithTranslation } from 'next-i18next'

import ErrorBoundary from '@/components/ErrorBoundary'
import { EN_TITLE, KO_TITLE } from '@/constants/config'
import nextI18nConfig from '@/next-i18next.config'

import '@/styles/globals.css'

type Props = AppProps<SSRConfig>

// eslint-disable-next-line @typescript-eslint/naming-convention
const InsideRecoilApp = ({ Component, pageProps }: Props) => {
  const { locale } = useRouter()

  useEffect(() => {
    if (locale === 'ko') {
      require(`dayjs/locale/ko`)
      dayjs.locale(locale)
    } else if (locale === 'en') {
      require(`dayjs/locale/en`)
      dayjs.locale(locale)
    }
  }, [locale])

  return (
    <ConfigProvider locale={locale === 'ko' ? koKR : enUS}>
      <Component {...pageProps} />
    </ConfigProvider>
  )
}

function MyApp(appProps: Props) {
  const router = useRouter()

  const { pathname, locale } = router

  if (
    !appProps?.pageProps?._nextI18Next &&
    !pathname.includes('/admin') &&
    !pathname.includes('/_error')
  ) {
    throw new Error(`${pathname}의 pageProps에 _nextI18Next가 없습니다.`)
  }

  const body = (
    <ErrorBoundary router={router}>
      <InsideRecoilApp {...appProps} />
    </ErrorBoundary>
  )

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
        <meta
          content={locale === 'ko' ? KO_TITLE : EN_TITLE}
          name="application-name"
        />
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA4_TRACKING_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${process.env.NEXT_PUBLIC_GA4_TRACKING_ID}');
        `}
      </Script>
      {body}
    </>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig)
