import { useRouter } from 'next/router'

import React from 'react'

import { faEdit, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FloatButton } from 'antd'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import { LOCALIZED_TITLE, MAIN_IMAGE, SITE_URL } from '@/constants/config'
import { OgType } from '@/types'
import { isBrowser } from '@/utils/env'

type Props = {
  children: React.ReactNode
  title?: string
  description?: string
  openGraph?: OpenGraph
}

function Layout({
  children,
  title: titleProps,
  description,
  openGraph,
}: Props) {
  const { locale, asPath, pathname } = useRouter()
  const localeSitename = LOCALIZED_TITLE[locale]
  const title = (titleProps ? `${titleProps} | ` : '') + localeSitename
  const url = isBrowser ? window.location.href : SITE_URL + asPath
  const isAdmin = process.env.NODE_ENV === 'development'
  const isDetailPage = pathname.match(/^\/[a-z]+/)

  return (
    <>
      <NextSeo
        description={openGraph?.description ?? description}
        openGraph={{
          type: OgType.WEBSITE,
          title,
          siteName: localeSitename,
          description,
          url,
          locale,
          images: [
            {
              url: MAIN_IMAGE,
              width: 1200,
              height: 630,
              alt: localeSitename,
            },
          ],
          ...openGraph,
        }}
        title={title}
        twitter={{
          cardType: 'summary_large_image',
          site: url,
        }}
      />
      <div>
        <Header />
        <main className="min-h-screen bg-background text-foreground">
          {children}
        </main>
        <Footer />
      </div>
      {isAdmin && (
        <>
          <FloatButton.Group
            shape="square"
            style={{
              insetInlineEnd: 'unset',
              insetInlineStart: 24,
              bottom: 70,
            }}>
            {isAdmin && (
              <FloatButton
                icon={<FontAwesomeIcon icon={faGear} />}
                type="primary"
                onClick={() => {
                  const adminPathname = '/admin/chefs'
                  window.open(adminPathname, '_blank')
                }}
              />
            )}
            {isAdmin && isDetailPage && (
              <FloatButton
                icon={<FontAwesomeIcon icon={faEdit} />}
                type="primary"
                onClick={() => {
                  const adminPathname = '/admin' + asPath
                  window.open(adminPathname, '_blank')
                }}
              />
            )}
          </FloatButton.Group>
          <FloatButton.Group shape="circle" style={{ bottom: 70 }}>
            <FloatButton.BackTop />
          </FloatButton.Group>
        </>
      )}
    </>
  )
}

export default Layout
