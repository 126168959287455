export const KO_TITLE = '흑백요리사: 요리 계급 전쟁'
export const EN_TITLE = 'The Culinary Class Wars'

export const LOCALIZED_TITLE = {
  ko: KO_TITLE,
  en: EN_TITLE,
}

export const MAIN_IMAGE =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/ad7cafa9-2f86-4404-2d54-e879caa8f900/public'

export const SITE_URL = 'https://https://culinary-class-wars.com/'
