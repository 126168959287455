import { ReactNode } from 'react'

export enum Locale {
  KO = 'ko',
  EN = 'en',
  MS = 'ms',
}

export const locales = [Locale.KO, Locale.EN, Locale.MS]

export type LocalizedString = Partial<Record<Locale, string>>

/** Firestore 데이터에 공통으로 들어가는 필드 */
export interface FirestoreCommon {
  id: string
  createdAt: string
  updatedAt: string
}

export interface Book {
  id: string
  name: LocalizedString
  publisher: LocalizedString
  publicationDate: LocalizedString
  images: FormImage[]
  link?: string
}

export enum ChefClass {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  JUDGE = 'JUDGE',
}
export enum SNS {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  X = 'X',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export interface FormImage {
  name: string
  url: string
}

export interface Chef extends FirestoreCommon {
  name: LocalizedString
  nickname?: LocalizedString
  class: ChefClass
  description: LocalizedString
  images: FormImage[]
  netflixOfficialImages?: FormImage[]
  career?: {
    title: LocalizedString
    description: LocalizedString
  }[]
  restaurants?: Restaurant[]
  cookingStyle?: LocalizedString
  books?: Book[]
  snsList?: {
    url: string
  }[]
  finalRank?: number
}

export interface Restaurant {
  id: string
  name: LocalizedString
  address: LocalizedString
  images: FormImage[]
  priceRange?: string
  cuisine?: LocalizedString
  links?: {
    url: string
  }[]
}

export const LOCALE_MAP: Record<
  Locale,
  {
    label: string
    emoji: string
  }
> = {
  [Locale.KO]: {
    label: 'Korea',
    emoji: '🇰🇷',
  },
  [Locale.EN]: {
    label: 'English',
    emoji: '🇬🇧',
  },
  [Locale.MS]: {
    label: 'Malay',
    emoji: '🇲🇾',
  },
}

export type MenuConfig = (SingleMenu | ParentMenu)[]

export enum MenuTag {
  NEW = 'NEW',
  HOT = 'HOT',
  UPDATE = 'UPDATE',
}

export enum AdminRole {
  /** 관리자 */
  ADMIN = 2,
  /** 멤버 */
  MEMBER = 1,
  /** 일반 유저 */
  USER = 0,
}

export interface Menu {
  title: string
  pathname: string
  tags?: MenuTag[]
  level?: AdminRole
}

export interface SingleMenu extends Menu {
  icon: ReactNode
  hasSubMenu: false
  /** 파생되는 pathname 배열
   * * 해당 pathname에 가게되면 해당 메뉴가 활성화됩니다.
   */
  linkedPathnames?: string[]
}

export interface ParentMenu extends Menu {
  hasSubMenu: true
  icon: ReactNode
  childrenMenu: ChildMenu[]
}

export type ChildMenu = Menu & {
  /** 파생되는 pathname 배열
   * * 해당 pathname에 가게되면 해당 메뉴가 활성화됩니다.
   */
  linkedPathnames?: string[]
}

export enum MenuPosition {
  TOP = 'TOP',
  LEFT = 'LEFT',
}

export interface Config {
  id: string
  title: string
  description: string
  menus: MenuConfig
  hasMenu: boolean
  menuPosition: MenuPosition
}

export enum OgType {
  WEBSITE = 'website',
}
