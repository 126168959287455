import Link from 'next/link'
import { useRouter } from 'next/router'

import { useState } from 'react'

import { Menu, X, Globe, ChevronDown } from 'lucide-react'
import { useTranslation } from 'next-i18next'

import Img from '@/components/common/Img'
import { EN_LOGO_IMAGE, KO_LOGO_IMAGE } from '@/constants/image'
import { locales } from '@/types'

function Header() {
  const { t } = useTranslation('common')
  const { locale, push, pathname, asPath, query } = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)

  const changeLocale = (nextLocale: string) => {
    push({ pathname, query }, asPath, { locale: nextLocale })
  }

  const navItems = [
    { name: t('Header.home'), path: '/' },
    { name: t('Header.program'), path: '/programs' },
    { name: t('Header.chefs'), path: '/chefs' },
    { name: t('Header.restaurants'), path: '/restaurants' },
  ]
  return (
    <header className="bg-black text-white">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link href="/">
            {locale === 'ko' ? (
              <Img
                alt="한국 로고"
                height={100}
                src={KO_LOGO_IMAGE}
                width={100}
              />
            ) : (
              <Img
                alt="영어 로고"
                height={100}
                src={EN_LOGO_IMAGE}
                width={100}
              />
            )}
          </Link>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
          <ul className="hidden md:flex space-x-6 items-center">
            {navItems.map((item) => {
              const isActive =
                item.path === '/'
                  ? pathname === item.path
                  : pathname.startsWith(item.path)
              return (
                <li key={item.path}>
                  <Link
                    className={`hover:text-gray-300 transition-colors ${
                      isActive ? 'text-white font-bold' : 'text-gray-400'
                    }`}
                    href={item.path}>
                    {item.name}
                  </Link>
                </li>
              )
            })}
            <li className="relative">
              <button
                className="flex items-center space-x-1 hover:text-gray-300"
                onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}>
                <Globe size={20} />
                <span>{t(`Navigation.language.${locale}`)}</span>
                <ChevronDown size={16} />
              </button>
              {isLanguageMenuOpen && (
                <ul className="absolute right-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg z-50">
                  {locales.map((_locale) => (
                    <li key={_locale}>
                      <button
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                        onClick={() => changeLocale(_locale)}>
                        {t(`Navigation.language.${_locale}`)}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
        {isMenuOpen && (
          <div className="md:hidden mt-4">
            <ul className="flex flex-col space-y-4">
              {navItems.map((item) => (
                <li key={item.path}>
                  <Link
                    className={`block hover:text-gray-300 transition-colors ${
                      pathname === item.path
                        ? 'text-white font-bold'
                        : 'text-gray-400'
                    }`}
                    href={item.path}
                    onClick={() => setIsMenuOpen(false)}>
                    {item.name}
                  </Link>
                </li>
              ))}
              <li>
                <button
                  className="flex items-center space-x-1 hover:text-gray-300"
                  onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}>
                  <Globe size={20} />
                  <span>{t(`Navigation.language.${locale}`)}</span>
                  <ChevronDown size={16} />
                </button>
                {isLanguageMenuOpen && (
                  <ul className="mt-2 space-y-2 bg-gray-800 rounded-md shadow-lg z-50">
                    {locales.map((_locale) => (
                      <li key={_locale}>
                        <button
                          className="block w-full text-left py-2 px-4 hover:bg-gray-700 hover:text-gray-300"
                          onClick={() => changeLocale(_locale)}>
                          {t(`Navigation.language.${_locale}`)}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  )
}

export default Header
