import Link from 'next/link'

import React from 'react'

import { useTranslation } from 'next-i18next'

const Footer: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <footer className="bg-gray-800 text-gray-300 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">{t('Footer.about')}</h3>
            <p className="text-sm break-keep">{t('Footer.description')}</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <ul className="text-sm">
              <li className="mb-2">
                <Link className="hover:text-white transition" href="/">
                  {t('Footer.home')}
                </Link>
              </li>
              <li className="mb-2">
                <Link className="hover:text-white transition" href="/programs">
                  {t('Footer.programs')}
                </Link>
              </li>
              <li className="mb-2">
                <Link className="hover:text-white transition" href="/chefs">
                  {t('Footer.chefs')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
