import { useRouter } from 'next/router'

import { useEffect } from 'react'

import { i18n } from 'next-i18next'

import en from '@/public/locales/en/common.json'
import ko from '@/public/locales/ko/common.json'

import Layout from 'components/Layout'

interface Props {
  statusCode: number
  message?: string
}

function Error({ statusCode, message }: Props) {
  const { locale } = useRouter()

  useEffect(() => {
    i18n?.addResourceBundle(locale, 'common', locale === 'ko' ? ko : en)
  }, [locale])

  return <Layout>에러 페이지</Layout>
}

Error.getInitialProps = ({ response, error }) => {
  const result = response
    ? { statusCode: response.statusCode, message: response.message }
    : error
    ? { statusCode: error.statusCode, message: response.message }
    : { statusCode: 404 }
  return result
}

export default Error
